import * as React from "react"
import { Link, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import { config } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faFacebookF } from "@fortawesome/free-brands-svg-icons"
import { faCommentDots } from "@fortawesome/free-solid-svg-icons"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

config.autoAddCss = false

type ShareType = {
  title: string
  href: string
  twitter?: string
  feedbackUrl?: string
}

const Share: React.FC<ShareType> = ({ title, href, twitter, feedbackUrl }) => {
  return (
    <div className="share">
      <div className="action">
        {[
          {
            key: "twitter",
            icon: faTwitter,
            url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
              href
            )}&text=${encodeURIComponent(
              `${title}${twitter ? ` by @${twitter}` : ``}`
            )}`,
          },
          {
            key: "facebook",
            icon: faFacebookF,
            url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              href
            )}`,
          },
          {
            key: "discussion",
            icon: faCommentDots,
            url: feedbackUrl,
          },
        ]
          .filter(item => item.url)
          .map(item => (
            <a
              key={item.key}
              className="button primary"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
            >
              <FontAwesomeIcon className="icon" icon={item.icon} />
            </a>
          ))}
      </div>
    </div>
  )
}

type BlogPostTemplateType = {
  data: GatsbyTypes.BlogPostBySlugQuery
  location: Location
}

const BlogPostTemplate: React.FC<BlogPostTemplateType> = ({
  data: { previous, next, site, markdownRemark: post },
  location,
}) => {
  const siteTitle = site?.siteMetadata?.title || `Title`

  const ShareContainer = () => {
    const title = post?.frontmatter?.title
    const twitter = site?.siteMetadata?.social?.twitter
    return title && twitter ? (
      <Share
        title={post?.frontmatter?.title}
        twitter={site?.siteMetadata?.social?.twitter}
        href={`${site?.siteMetadata?.siteUrl}${post?.fields?.slug}`}
        feedbackUrl={site?.siteMetadata?.social?.feedbackUrl ?? undefined}
      />
    ) : null
  }

  return (
    <Layout location={location} title={siteTitle}>
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post?.frontmatter?.title}</h1>
          <p>{post?.frontmatter?.date}</p>
        </header>
        <ShareContainer />
        <section
          dangerouslySetInnerHTML={{ __html: post?.html ?? `` }}
          itemProp="articleBody"
        />
        <ShareContainer />
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-around`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li style={{ width: "100%", textAlign: "center" }}>
            <Link to="/">ニュースレターの一覧</Link>
          </li>
          {previous?.fields?.slug && (
            <li style={{ textAlign: "center" }}>
              <Link to={previous?.fields?.slug} rel="prev">
                ⬅ {previous?.frontmatter?.title}
              </Link>
            </li>
          )}
          {next?.fields?.slug && (
            <li style={{ textAlign: "center" }}>
              <Link to={next?.fields?.slug} rel="next">
                {next?.frontmatter?.title} ➡
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </Layout>
  )
}

type HeadType = { data: GatsbyTypes.BlogPostBySlugQuery }

export const Head: React.FC<HeadType> = ({
  data: { markdownRemark: post },
}) => {
  const image = post?.frontmatter?.image?.childImageSharp
  return (
    <Seo
      article={post?.fields?.slug ? { pathname: post?.fields?.slug } : {}}
      title={post?.frontmatter?.title ?? undefined}
      description={post?.frontmatter?.description ?? post?.excerpt ?? undefined}
      image={image ? getSrc(image) : undefined}
    />
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          twitter
          feedbackUrl
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            gatsbyImageData(width: 1200)
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
